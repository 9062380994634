import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="box">
      <div className="title has-text-primary">
        NOT FOUND
      </div>
      <div className="content">
        <p>Is there a broken link?  If so let me know please.</p>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
